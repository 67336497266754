import React, { useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
	AnalyticsIcon,
	CartBuyIcon,
	ChatIcon,
	ChevronIconRight,
	ClipboardCheckIcon,
	HomeSimpleIcon,
	LegalIcon,
	ListOrderedIcon,
	QuestionCircleIcon,
	SigninIcon,
	TagIcon,
	TeamIcon,
	UserIcon,
	UserPlusIcon,
} from '../SVGIcons/SVGIcons';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import DirectedTutorialModal from '../Tutorial/DirectedTutorialModal';
import useWindowSize from '../../hooks/useWindowSize';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useCompVis from '../../hooks/useCompVis';
import { sliceString } from '../../helpers';

export default function SidebarNav({ textboxesOpen, openTextbox, extendLeftSidebar, handleLeftSidebar, subNavChoice }) {
	const { userState, userDispatch } = useContext(CurrentUserContext);
	const { teamState } = useContext(CurrentTeamContext);

	let location = useLocation();

	return (
		<>
			{userState?.isAuthenticated ? (
				<>
					<div className='innerCol'>
						<NavLink to='/' className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
							<HomeSimpleIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Home</h4>}
						</NavLink>
						<div
							tabIndex={0}
							className={`navItem${
								location.pathname === '/order' ||
								location.search.includes('type=1') ||
								location.pathname.includes('/suppliers')
									? ' highlight buy'
									: ''
							}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'buy' ? 2 : 1,
									subNavChoice: subNavChoice !== 'buy' ? 'buy' : null,
									isNavigationItem: true,
								})
							}>
							<CartBuyIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && (
								<>
									<h4>Buy</h4>
									<ChevronIconRight iconClass='chevronIcon navIcon' />
								</>
							)}
							<DirectedTutorialModal
								page='dashboard'
								order={5}
								header='Orders'
								right={50}
								left={-25}
								callback={() =>
									handleLeftSidebar({ openState: 0, subNavChoice: null, isNavigationItem: true })
								}
								body={
									<>
										At any time you can place an order.{' '}
										<a href='/manage'>Find out more about orders</a> and how to place one.
									</>
								}
							/>
						</div>
						<div
							tabIndex={0}
							className={`navItem${
								(location.pathname.includes('/orders') && location.search.includes('type=2')) ||
								location.pathname.includes('/progress')
									? ' highlight sell'
									: ''
							}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'sell' ? 2 : 1,
									subNavChoice: subNavChoice !== 'sell' ? 'sell' : null,
									isNavigationItem: true,
								})
							}>
							<TagIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && (
								<>
									<h4>Sell</h4>
									<ChevronIconRight iconClass='chevronIcon navIcon' />
								</>
							)}
						</div>
						<NavLink
							to='/messages'
							className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
							<ChatIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Messaging</h4>}
						</NavLink>
						<div
							tabIndex={0}
							className={`navItem${
								location.pathname.includes('/orders') &&
								!location.pathname.includes('/orders/') &&
								location.search === ''
									? ' highlight'
									: ''
							}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'orders' ? 2 : 1,
									subNavChoice: subNavChoice !== 'orders' ? 'orders' : null,
									isNavigationItem: true,
								})
							}>
							<ClipboardCheckIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && (
								<>
									<h4>Orders</h4>
									<ChevronIconRight iconClass='chevronIcon navIcon' />
								</>
							)}
						</div>
						<Link to='/' className='navItem disabledDiv'>
							<AnalyticsIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Analytics</h4>}
						</Link>
					</div>
					<div className='innerCol'>
						<div
							tabIndex={0}
							className={`navItem${location.pathname.includes('/tutorials') ? ' highlight' : ''}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'support' ? 2 : 1,
									subNavChoice: subNavChoice !== 'support' ? 'support' : null,
									isNavigationItem: true,
								})
							}>
							<QuestionCircleIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && (
								<>
									<h4>Support</h4>
									<ChevronIconRight iconClass='chevronIcon navIcon' />
								</>
							)}
						</div>
						<div
							tabIndex={0}
							className={`navItem${
								location.pathname.includes('/privacy') ||
								location.pathname.includes('/policy') ||
								location.pathname.includes('/terms')
									? ' highlight'
									: ''
							}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'legal' ? 2 : 1,
									subNavChoice: subNavChoice !== 'legal' ? 'legal' : null,
									isNavigationItem: true,
								})
							}>
							<LegalIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && (
								<>
									<h4>Legal</h4>
									<ChevronIconRight iconClass='chevronIcon navIcon' />
								</>
							)}
						</div>
						<div className='colSegment'>
							<div
								tabIndex={0}
								className={teamState.isTeamOfOne ? 'navItem' : 'navItem noPadding'}
								onClick={() =>
									handleLeftSidebar({
										openState: subNavChoice !== 'manage' ? 2 : 1,
										subNavChoice: subNavChoice !== 'manage' ? 'manage' : null,
										isNavigationItem: true,
									})
								}
								onKeyDown={(e) => {
									e.key == 'Enter'
										? handleLeftSidebar({
												openState: subNavChoice !== 'manage' ? 2 : 1,
												subNavChoice: subNavChoice !== 'manage' ? 'manage' : null,
												isNavigationItem: true,
										  })
										: null;
								}}>
								<DirectedTutorialModal
									page='dashboard'
									order={3}
									flip='flipRight'
									flipVertical
									size='wide'
									header='Teams'
									body={
										<>
											A Team is a collection of individuals with shared purpose. Examples are a
											company, a corporate group, a department or an office. You can create a Team
											at any time and you can be a member of multiple teams. Here you can select
											the Team you want to be within for buying and selling.{' '}
											<a href='/manage'>Find out more about Teams</a> and how you can register
											one.
										</>
									}
								/>
								{teamState.isTeamOfOne ? (
									<>
										<TeamIcon iconClass='sidebarIcon navIcon' style={{ marginRight: '10px' }} />
										{extendLeftSidebar !== 0 && (
											<p>
												{sliceString(teamState.currentTeam.teamInfo.teamName, 0, 40, true) ||
													'Personal'}
											</p>
										)}
									</>
								) : (
									<>
										{teamState.currentTeam?.teamInfo?.teamName ? (
											<>
												{teamState.currentTeam?.teamIcon ? (
													<img
														src={teamState.currentTeam?.teamIcon}
														alt='teamIcon navIcon'
														className='profileImg'
													/>
												) : (
													<div
														className={`teamChoicesIcon${
															teamState.currentTeam?.avatarColor
																? ` ${teamState.currentTeam?.avatarColor}`
																: ''
														}`}>
														{teamState.currentTeam?.teamInitials
															?.slice(0, 2)
															?.toUpperCase()}
													</div>
												)}
												{extendLeftSidebar !== 0 && (
													<h4>{teamState.currentTeam?.teamInfo?.teamName}</h4>
												)}
											</>
										) : (
											<>
												<TeamIcon
													iconClass='sidebarIcon navIcon'
													style={{ marginRight: '10px' }}
												/>
												{extendLeftSidebar !== 0 && (
													<p>
														{sliceString(
															teamState.currentTeam.teamInfo.teamName,
															0,
															40,
															true
														) || 'Personal'}
													</p>
												)}
											</>
										)}
									</>
								)}
								{extendLeftSidebar !== 0 && <ChevronIconRight iconClass='chevronIcon navIcon' />}
							</div>
							<div
								tabIndex={0}
								className={
									userState?.isAuthenticated && userState?.currUser.avatar
										? 'navItem noPadding'
										: 'navItem'
								}
								onClick={() =>
									handleLeftSidebar({
										openState: subNavChoice !== 'user' ? 2 : 1,
										subNavChoice: subNavChoice !== 'user' ? 'user' : null,
										isNavigationItem: true,
									})
								}
								onKeyDown={(e) => {
									e.key == 'Enter'
										? handleLeftSidebar({
												openState: subNavChoice !== 'user' ? 2 : 1,
												subNavChoice: subNavChoice !== 'user' ? 'user' : null,
												isNavigationItem: true,
										  })
										: null;
								}}>
								<DirectedTutorialModal
									page='dashboard'
									order={4}
									flip='flipRight'
									flipVertical
									size='wide'
									header='Personas'
									body={
										<>
											You may have multiple email addresses. You can register each one with us and
											switch between them. You may be in different Teams for each email address
											used. <a href='/manage'>Find out more about Personas</a> and how to add one.
										</>
									}
								/>
								{userState?.isAuthenticated && userState?.currUser?.avatar ? (
									<img src={userState?.currUser?.avatar?.url} alt='avatar' className='profileImg' />
								) : (
									<UserIcon iconClass='sidebarIcon navIcon' />
								)}
								{extendLeftSidebar !== 0 && (
									<div className='currUserProfile'>
										<h4>{userState?.currUser?.name}</h4>
										<p>{sliceString(userState?.currUser?.email, 0, 17, true)}</p>
									</div>
								)}
								{extendLeftSidebar !== 0 && <ChevronIconRight iconClass='chevronIcon navIcon' />}
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='innerCol'>
						<Link
							to='/features'
							className={`navItem${
								location.pathname.includes('/features') && !location.pathname.includes('/features/')
									? ' highlight'
									: ''
							}`}>
							<HomeSimpleIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Home</h4>}
						</Link>
						<div
							className={`navItem${
								location.pathname.includes('/features/') &&
								!location.pathname.includes('/features/apply')
									? ' highlight'
									: ''
							}`}
							onClick={() =>
								handleLeftSidebar({
									openState: subNavChoice !== 'features' ? 2 : 1,
									subNavChoice: subNavChoice !== 'features' ? 'features' : null,
									isNavigationItem: true,
								})
							}>
							<ListOrderedIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Procuur Features</h4>}
						</div>
						<NavLink
							to='/features/apply'
							className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
							<UserPlusIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Apply for registration</h4>}
						</NavLink>
						<Link
							to='#'
							className='navItem'
							onClick={() => {
								userDispatch({ type: 'LOGIN' });
							}}>
							<SigninIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Login</h4>}
						</Link>
					</div>
					<div className='innerCol'>
						<div className='navItem disabledDiv'>
							<ChatIcon iconClass='sidebarIcon navIcon' />
							{extendLeftSidebar !== 0 && <h4>Contact us</h4>}
						</div>
						<div className='innerCol'>
							<div
								tabIndex={0}
								className={`navItem${location.pathname.includes('/tutorials') ? ' highlight' : ''}`}
								onClick={() =>
									handleLeftSidebar({
										openState: subNavChoice !== 'support' ? 2 : 1,
										subNavChoice: subNavChoice !== 'support' ? 'support' : null,
										isNavigationItem: true,
									})
								}>
								<QuestionCircleIcon iconClass='sidebarIcon navIcon' />
								{extendLeftSidebar !== 0 && <h4>Support</h4>}
							</div>
							<div
								tabIndex={0}
								className={`navItem${
									location.pathname.includes('/privacy') ||
									location.pathname.includes('/policy') ||
									location.pathname.includes('/terms')
										? ' highlight'
										: ''
								}`}
								onClick={() =>
									handleLeftSidebar({
										openState: subNavChoice !== 'legal' ? 2 : 1,
										subNavChoice: subNavChoice !== 'legal' ? 'legal' : null,
										isNavigationItem: true,
									})
								}>
								<LegalIcon iconClass='sidebarIcon navIcon' />
								{extendLeftSidebar !== 0 && <h4>Legal</h4>}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
