import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import {
	areDifferentDays,
	breakpoints,
	checkSufficientSubscriptionLevel,
	clarifyDate,
	formatUserName,
	mapMessageFields,
	splitUserId,
} from '../../helpers';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import TeamMessagingWrapper from './components/TeamMessagingWrapper';
import ChatBox from './components/ChatBox';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SocketContext } from '../../App';
import ChatSelectColumn from './components/ChatSelectColumn';
import Axios from 'axios';
import ChatWritingAnimation from './components/ChatWritingAnimation';
import WrapperHeader from './components/WrapperHeader';
import MissingChatState from './components/MissingChatState';
import { useSearchParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import usePost from '../../hooks/usePost';
import { ClipboardCheckIcon, CustomerIcon, UserIcon } from '../../components/SVGIcons/SVGIcons';
import SubscriptionAdvertModal from '../../components/Modals/SubscriptionAdvertModal';
import { useModalPortal } from '../../contexts/ModalPortalContext';

export default function TeamMessaging() {
	const { fetch } = useFetch();
	const { post } = usePost();

	const [searchParams, setSearchParams] = useSearchParams();

	const tabPreset = [
		{ name: 'Orders', type: 'order', tabIndex: 1, icon: <ClipboardCheckIcon /> },
		{ name: 'Team', type: 'personal', tabIndex: 2, icon: <UserIcon /> },
		{ name: 'External', type: 'team', tabIndex: 3, icon: <CustomerIcon /> },
	];

	const [rooms, setRooms] = useState(new Map());
	const [tabs, setTabs] = useState(new Map(tabPreset.map((tab) => [tab.tabIndex, tab])));
	const [fetchedParticipants, setFetchedParticipants] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [totalRooms, setTotalRooms] = useState(new Map());
	const [roomsSkip, setRoomsSkip] = useState({
		order: { roomsFetched: false, skip: 0 },
		team: { roomsFetched: false, skip: 0 },
		personal: { roomsFetched: false, skip: 0 },
	});
	const [roomLimit, setRoomLimit] = useState(12);
	const [messageLimit, setMessageLimit] = useState(20);
	const [selectedTab, setSelectedTab] = useState(1);
	const [inputFilled, setInputFilled] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [messageFetchLoading, setMessageFetchLoading] = useState(false);
	const [chatStarted, setChatStarted] = useState(false);
	const [socketJoined, setSocketJoined] = useState(false);
	const [chatRoomSearch, setChatRoomSearch] = useState('');

	const { openModalPortal, closeModalPortal } = useModalPortal();

	const [roomsToPrep, setRoomsToPrep] = useState(null);

	const divEnd = useRef();
	const message = useRef();
	const chatBox = useRef();
	const pageContainerRef = useRef(null);

	const { width } = useWindowSize();
	let { notificationsDispatch } = useContext(NotificationContext);
	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	let socket = useContext(SocketContext);

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoaded(false);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setChatRoomSearch(searchValue.searchValue);
		}),
		[]
	);

	const setSearchParam = ({ key, value }) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	};

	const scrollChatToBottom = () => {
		const chat = chatBox.current;
		chat?.childNodes[chat?.childNodes?.length - 1]?.scrollIntoView({
			block: 'end',
		});
	};

	const handleOpenModalPortal = () => {
		openModalPortal(
			<SubscriptionAdvertModal
				subscriptionLevelName={'Business'}
				featureName={'internal messaging'}
				close={closeModalPortal}
			/>,
			pageContainerRef.current
		);
	};

	const handleSelectTab = (tab) => {
		if (tab !== 2) {
			setSelectedTab(tab);
		} else if (checkSufficientSubscriptionLevel(teamState?.currentTeam?.subscription, 'internalMessaging', 1)) {
			setSelectedTab(tab);
		} else {
			handleOpenModalPortal();
		}
	};

	const handleMessageSkip = async (room) => {
		let updatedRoom = { ...room };
		let topmostMessage = chatBox?.current?.childNodes[1];
		let newSkip = 0;
		setMessageFetchLoading(true);

		if (updatedRoom?.messageSkip != null && updatedRoom?.messageSkip != undefined) {
			newSkip = updatedRoom.messageSkip + messageLimit;
		} else {
			newSkip = newSkip + messageLimit;
		}
		if (updatedRoom?.totalMessages > newSkip) {
			let fetchedRoom = await getRoom(updatedRoom?.roomUuid, updatedRoom?.type, newSkip);
			fetchedRoom.messageSkip = newSkip;

			if (fetchedRoom?.messages?.length !== 0 && room?.messages && room?.messages?.length !== 0) {
				fetchedRoom.messages = [...fetchedRoom?.messages, ...updatedRoom.messages];
			}

			topmostMessage.scrollIntoView({ block: 'center' });

			setRooms((prevRooms) => {
				const newRooms = new Map(prevRooms);
				const updatedFetchedRoom = { ...fetchedRoom };
				newRooms.set(updatedFetchedRoom.roomUuid, updatedFetchedRoom);
				return newRooms;
			});

			queUpRoomsForPrep([fetchedRoom]);
		}
		setMessageFetchLoading(false);
	};

	function queUpRoomsForPrep(roomsForPrep) {
		if (!roomsForPrep) {
			return;
		}
		setRoomsToPrep((prevRoomsToPrep) => {
			const newRoomsToPrep = new Map(prevRoomsToPrep);
			roomsForPrep.forEach((roomToPrep) => {
				newRoomsToPrep.set(roomToPrep.roomUuid, { isPrepped: false });
			});
			if (newRoomsToPrep) {
				return newRoomsToPrep;
			} else {
				return prevRoomsToPrep;
			}
		});
	}

	const handleScroll = (e) => {
		let room = rooms.get(selectedRoom);
		if (!room) {
			return;
		}
		if (!e) {
			patchChatToRead(room.roomUuid);
			notificationsDispatch({ type: 'SET_ALL_MESSAGES_READ', payload: room.roomUuid });
			return;
		}
		const top = Math.round(e.target.scrollTop) === 0;
		const bottom =
			Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) === Math.round(e.target.clientHeight);

		if (top && messageFetchLoading === false) {
			handleMessageSkip(room);
		}
		if (bottom) {
			notificationsDispatch({ type: 'SET_ALL_MESSAGES_READ', payload: room.roomUuid });
		}
	};

	const handleSelectRoom = (roomUuid) => {
		const newSelectedRoom = roomUuid;
		setSearchParam({ key: 'roomUuid', value: roomUuid });
		socket.emit('leave-room', selectedRoom);
		socket.emit('join-room', newSelectedRoom);

		notificationsDispatch({ type: 'SET_ALL_MESSAGES_READ', payload: newSelectedRoom });
		setSelectedRoom(newSelectedRoom);

		let storedRoomMessage = localStorage.getItem(`storedRoomMessage.${roomUuid}`);

		if (message?.current) {
			if (storedRoomMessage) {
				message.current.value = storedRoomMessage;
				setInputFilled(true);
			} else {
				message.current.value = '';
			}
		}
	};

	const handleRoomSkip = (type, roomsFetched) => {
		setRoomsSkip((roomsSkip) => ({
			...roomsSkip,
			[type]: {
				roomsFetched: roomsFetched,
				skip: roomsFetched === false ? roomsSkip[type].skip + roomLimit : roomsSkip[type].skip,
			},
		}));
	};

	function handleChange() {
		if (message.current.value !== '') {
			setInputFilled(true);
			socket.emit('user-writing', {
				roomUuid: selectedRoom,
				recipientTeamUuid: rooms.get(selectedRoom)?.otherParty?.teamUuid || teamState.currentTeam.teamUuid,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				user: { name: userState?.currUser?.name, userUuid: splitUserId(userState?.currUser?.sub) },
			});

			localStorage.setItem(`storedRoomMessage.${selectedRoom}`, message?.current?.value);
		} else {
			localStorage.removeItem(`storedRoomMessage.${selectedRoom}`);
			setInputFilled(false);
		}
	}

	async function prepMessages(room) {
		const roomCopy = { ...room };
		const { messages } = roomCopy;
		const preppedMessages = [];

		for (let i = 0; i < messages?.length; i++) {
			if (messages[i]?.type === 'separator') {
				messages.splice(i, 1);
			}
		}

		let prevDateSeparator = null;
		for (let i = 0; i < messages?.length; i++) {
			let clarifiedDate = clarifyDate(messages[i]?.sentDateTime);

			if (
				messages[i - 1] &&
				messages[i] &&
				areDifferentDays(messages[i - 1].sentDateTime, messages[i].sentDateTime) &&
				clarifiedDate !== prevDateSeparator
			) {
				prevDateSeparator = clarifiedDate;
				preppedMessages.push({ type: 'separator', date: messages[i]?.sentDateTime });
			} else if (i === 0 && clarifiedDate !== prevDateSeparator) {
				prevDateSeparator = clarifiedDate;
				preppedMessages.unshift({ type: 'separator', date: messages[i]?.sentDateTime });
			}
			preppedMessages.push(messages[i]);
		}

		return preppedMessages;
	}

	async function prepRooms(newRooms) {
		let preppedRooms = JSON.parse(JSON.stringify(newRooms));
		let participants = [...fetchedParticipants];
		for (let i = 0; i < preppedRooms.length; i++) {
			if (preppedRooms[i]?.teamParticipants?.length === 2) {
				let reqList = [];
				let enrichedParticipants = [];
				let participantOneTeamUuid =
					typeof preppedRooms[i]?.teamParticipants[0] === 'object'
						? preppedRooms[i]?.teamParticipants[0]?.teamUuid
						: preppedRooms[i]?.teamParticipants[0];
				let participantTwoTeamUuid =
					typeof preppedRooms[i]?.teamParticipants[1] === 'object'
						? preppedRooms[i]?.teamParticipants[1]?.teamUuid
						: preppedRooms[i]?.teamParticipants[1];
				let participantOne = participants.find(
					(fetchedParticipant) =>
						fetchedParticipant?.teamUuid === preppedRooms[i]?.teamParticipants[0] ||
						fetchedParticipant?.teamUuid === preppedRooms[i]?.teamParticipants[0]?.teamUuid
				);
				let participantTwo = participants.find(
					(fetchedParticipant) =>
						fetchedParticipant?.teamUuid === preppedRooms[i]?.teamParticipants[1] ||
						fetchedParticipant?.teamUuid === preppedRooms[i]?.teamParticipants[1]?.teamUuid
				);
				if (participantOne) {
					enrichedParticipants.push(participantOne);
				} else {
					reqList.push(Axios.get(`${config.apiv1}/team/team.read/${participantOneTeamUuid}?format=minimal`));
				}
				if (participantTwo) {
					enrichedParticipants.push(participantTwo);
				} else {
					reqList.push(Axios.get(`${config.apiv1}/team/team.read/${participantTwoTeamUuid}?format=minimal`));
				}
				if (reqList.length !== 0) {
					let newFetchedParticipants = await Promise.all(reqList);
					newFetchedParticipants = newFetchedParticipants.map((participant) => participant?.data?.data);
					enrichedParticipants = [...enrichedParticipants, ...newFetchedParticipants];
					participants = [...participants, ...enrichedParticipants];

					setFetchedParticipants((fetchedParticipants) => [
						...fetchedParticipants,
						...newFetchedParticipants,
					]);
				}

				if (enrichedParticipants[0]?.teamInitials === 'ME') {
					enrichedParticipants[0] = await applyTeamOfOneUserData(enrichedParticipants[0]);
				}
				if (enrichedParticipants[1]?.teamInitials === 'ME') {
					enrichedParticipants[1] = await applyTeamOfOneUserData(enrichedParticipants[1]);
				}

				preppedRooms[i].teamParticipants = enrichedParticipants;

				await preppedRooms[i].teamParticipants.forEach((team) => {
					if (team && team?.teamUuid !== teamState?.currentTeam?.teamUuid) {
						preppedRooms[i].otherParty = team;
						return;
					}
				});

				if (preppedRooms[i]?.type === 'order' && preppedRooms[i]?.orderData?.orderRef) {
					preppedRooms[i].chatTitle = preppedRooms[i].orderData?.orderRef;
					preppedRooms[i].chatIcon = preppedRooms[i].otherParty?.teamIcon;
				} else if (preppedRooms[i].type === 'team' && preppedRooms[i].teamParticipants?.length === 2) {
					preppedRooms[i].chatTitle = preppedRooms[i].otherParty?.teamInfo?.teamName;
					preppedRooms[i].chatIcon = preppedRooms[i].otherParty?.teamIcon;
				}
			} else if (preppedRooms[i]?.userParticipants?.length === 2) {
				if (preppedRooms[i].type === 'personal') {
					preppedRooms[i].userParticipants.forEach((participant, key) => {
						teamState.currentTeam.members?.forEach((member) => {
							if (member.userUuid === participant && member.firstname) {
								preppedRooms[i].userParticipants[key] = member;
							}
							if (
								member.userUuid === participant &&
								member.userUuid !== splitUserId(userState.currUser.sub) &&
								member.firstname
							) {
								preppedRooms[i].otherUser = member;
							}
						});
					});
				}
				preppedRooms[i].chatTitle = `${
					preppedRooms[i].otherUser?.title ? `${preppedRooms[i].otherUser?.title} ` : ''
				}${preppedRooms[i].otherUser?.firstname} ${preppedRooms[i].otherUser?.lastname}`;
				preppedRooms[i].chatIcon = preppedRooms[i].otherUser?.avatar;
			}
			if (preppedRooms[i]?.messages?.length !== 0) {
				preppedRooms[i].messages = await prepMessages(preppedRooms[i]);
			}
		}
		return preppedRooms;
	}

	function handleTotalRooms(rooms, type) {
		if (rooms && type) {
			setTotalRooms((totalRooms) => {
				const updatedTotalRooms = new Map(totalRooms);
				updatedTotalRooms.set(type, rooms.totalDocuments);

				return updatedTotalRooms;
			});
		}
	}

	function handleNewMessage() {
		const userUuid = splitUserId(userState.currUser.sub);
		const room = rooms.get(selectedRoom);
		const name = `${userState.currUser?.prefix ? `${userState.currUser?.prefix} ` : ''}${
			userState.currUser?.given_name
		} ${userState.currUser?.family_name}`;

		const newMessage = {
			type: room.type,
			messageText: message.current.value,
			roomUuid: room.roomUuid ?? undefined,
			orderUuid: room.orderUuid ?? undefined,
			buyerTeamUuid: room.orderData?.buyerTeamUuid || undefined,
			sellerTeamUuid: room.orderData?.sellerTeamUuid || undefined,
			buyerUserUuid: room.orderData?.buyerUserUuid || undefined,
			sellerUserUuid: room.orderData?.sellerUserUuid || undefined,
			teamParticipants: room.teamParticipants.map((team) => {
				if (team?.teamUuid) {
					return team.teamUuid;
				} else if (team && typeof team === 'string') {
					return team;
				}
			}),
			userParticipants: room.userParticipants.map((user) => {
				if (user?.userUuid) {
					return user.userUuid;
				} else if (user && typeof user === 'string') {
					return user;
				}
			}),
			buyerUserUuid:
				room.orderData?.buyerTeamUuid === teamState.currentTeam.teamUuid
					? userUuid
					: room.orderData?.buyerUserUuid,
			sellerUserUuid:
				room.orderData?.sellerTeamUuid === teamState.currentTeam.teamUuid
					? userUuid
					: room.orderData?.sellerUserUuid,
			buyerUserRef:
				room.orderData?.buyerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.name : null,
			sellerUserRef:
				room.orderData?.sellerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.name : null,
		};

		mapMessageFields({ message: newMessage, userUuid, name, teamUuid: teamState.currentTeam?.teamUuid, room });

		socket.emit('send-chat-message', newMessage);
		newMessage.sentDateTime = new Date();

		let updatedRoom = { ...room };

		updatedRoom.messages.push(newMessage);
		updatedRoom.updatedAt = new Date();
		updatedRoom.messageSkip =
			updatedRoom.messageSkip !== null && updatedRoom.messageSkip !== undefined ? updatedRoom.messageSkip + 1 : 1;
		updatedRoom.totalMessages =
			updatedRoom.totalMessages !== null && updatedRoom.totalMessages !== undefined
				? updatedRoom.totalMessages + 1
				: 1;

		if (!chatStarted) {
			setChatStarted(true);
		}

		setRooms((prevRooms) => {
			const newRooms = new Map(prevRooms);
			newRooms.set(selectedRoom, updatedRoom);
			return newRooms;
		});

		setTimeout(() => {
			chatBox.current.scrollTop = chatBox.current.scrollHeight;
		}, 100);
		message?.current?.focus();
		message.current.value = '';
		setInputFilled(false);

		localStorage.removeItem(`storedRoomMessage.${selectedRoom}`);
	}

	function handleNewRooms(rooms, replaceOldRooms) {
		if (!rooms) {
			return;
		}
		setRooms((prevRooms) => {
			const updatedRooms = new Map(replaceOldRooms === true ? [] : prevRooms);
			rooms.forEach((room) => {
				if (room) {
					updatedRooms.set(room.roomUuid, room);
				}
			});

			return updatedRooms;
		});
	}

	useEffect(() => {
		let mounted = true;
		let writingTimeouts = {};

		function cleanUpWritingTimout(userUuid) {
			clearTimeout(writingTimeouts[userUuid]);
			delete writingTimeouts[userUuid];
		}

		if (loaded && socket !== null && mounted) {
			if (rooms) {
				setSocketJoined(true);

				function filterOutUserWriting(user) {
					setRooms((prevRooms) => {
						const newRooms = new Map(prevRooms);
						if (newRooms) {
							for (const [key, room] of newRooms.entries()) {
								if (room?.usersWriting?.some((u) => u.userUuid === user.userUuid)) {
									newRooms.set(key, {
										...room,
										usersWriting: room.usersWriting.filter((u) => u.userUuid !== user.userUuid),
									});
								}
							}
							return newRooms;
						} else {
							return prevRooms;
						}
					});
					cleanUpWritingTimout(user.userUuid);
				}

				async function handleNewChatMessage(message) {
					let newRoom = {};
					if (!rooms.get(message.roomUuid)) {
						newRoom = await getRoom(message.roomUuid, message.type, 0);
					}
					if (message.messageSenderUserUuid === splitUserId(userState?.currUser?.sub)) {
						return;
					}
					setRooms((prevRooms) => {
						if (!prevRooms) prevRooms = new Map();

						const matchingRoom = [...prevRooms.values()].find((room) => room.roomUuid === message.roomUuid);
						const newRooms = new Map(prevRooms);

						if (matchingRoom) {
							const updatedRoom = {
								...matchingRoom,
								messages: [...matchingRoom.messages, message],
								unreadMessageCount:
									message.roomUuid !== selectedRoom
										? matchingRoom.unreadMessageCount + 1
										: matchingRoom.unreadMessageCount,
								totalMessages: matchingRoom.totalMessages + 1,
								messageSkip: matchingRoom.messageSkip ? matchingRoom.messageSkip + 1 : 1,
								updatedAt: new Date().toISOString(),
							};

							const matchingKey = [...prevRooms.entries()].find(
								([_, room]) => room.roomUuid === message.roomUuid
							)?.[0];
							if (matchingKey) {
								newRooms.set(matchingKey, updatedRoom);
							}

							return newRooms;
						} else if (newRoom) {
							if (newRoom) {
								newRooms.set(newRoom.roomUuid, newRoom);
								return newRooms;
							} else {
								return prevRooms;
							}
						} else {
							return prevRooms;
						}
					});

					queUpRoomsForPrep([newRoom]);

					if (message.roomUuid === selectedRoom) {
						handleScroll();
						scrollChatToBottom();
					}
				}

				// Socket chat events
				socket.on('chat-message', (message) => {
					handleNewChatMessage(message);
					filterOutUserWriting({ userUuid: message.messageSenderUserUuid });
				});

				socket.on('user-writing', (data) => {
					try {
						setRooms((prevRooms) => {
							const newRooms = new Map(prevRooms);

							const matchingRoomKey = [...newRooms.entries()].find(
								([_, room]) => room.roomUuid === data.roomUuid
							)?.[0];

							if (matchingRoomKey && data?.user?.userUuid !== splitUserId(userState.currUser.sub)) {
								const matchingRoom = newRooms.get(matchingRoomKey);
								if (!matchingRoom.usersWriting) {
									matchingRoom.usersWriting = [];
								}

								if (writingTimeouts[data?.user?.userUuid]) {
									cleanUpWritingTimout(data?.user?.userUuid);
								} else {
									matchingRoom.usersWriting = [...matchingRoom.usersWriting, { ...data.user }];
								}

								writingTimeouts[data?.user?.userUuid] = setTimeout(() => {
									filterOutUserWriting(data.user);
								}, 5000);
								newRooms.set(matchingRoomKey, { ...matchingRoom });
							}

							return newRooms;
						});
						scrollChatToBottom();
					} catch (err) {
						console.log(err);
					}
				});
			}
		}

		return () => {
			socket.off('user-writing');
			socket.off('chat-message');

			mounted = false;
		};
	}, [loaded, socket, chatStarted, selectedRoom]); // eslint-disable-next-line

	useEffect(() => {
		if (socket.disconnected) {
			socket.emit('join-room', selectedRoom);
		}
	}, [socket]);

	useEffect(() => {
		if (loaded && divEnd && message) {
			if (!rooms?.get(selectedRoom)?.messageSkip) {
				divEnd?.current?.scrollIntoView({ block: 'end' });
			}
			message?.current?.focus();
			patchChatToRead(selectedRoom);
		}
	}, [selectedRoom, divEnd, message]); // eslint-disable-next-line

	useEffect(async () => {
		setLoaded(false);
		const [orderRooms, teamRooms, personalRooms] = await Promise.allSettled([
			getTypeOfRooms(teamState?.currentTeam?.teamUuid, roomLimit, roomsSkip.order.skip, 'order', chatRoomSearch),
			getTypeOfRooms(teamState?.currentTeam?.teamUuid, roomLimit, roomsSkip.team.skip, 'team'),
			getTypeOfRooms(teamState?.currentTeam?.teamUuid, roomLimit, roomsSkip.personal.skip, 'personal'),
		]);

		if ((orderRooms.status === 'fulfilled' && orderRooms.value?.data?.length !== 0) || chatRoomSearch) {
			handleNewRooms(orderRooms.value?.data, true);
			queUpRoomsForPrep(orderRooms.value?.data);
			handleTotalRooms(orderRooms.value, 'order');
		}
		if (teamRooms.status === 'fulfilled' && teamRooms.value?.data?.length !== 0) {
			handleNewRooms(teamRooms.value?.data);
			queUpRoomsForPrep(teamRooms.value?.data);

			handleTotalRooms(teamRooms.value, 'team');
		}
		if (
			personalRooms.status === 'fulfilled' &&
			personalRooms.value?.data?.length !== 0 &&
			teamState?.currentTeam?.teamInitials !== 'ME'
		) {
			handleNewRooms(personalRooms.value?.data);
			queUpRoomsForPrep(personalRooms.value?.data);
			handleTotalRooms(personalRooms.value, 'personal');
		}

		let trustedSuppliers = await getTrustedTeams(teamState.currentTeam.teamUuid);

		if (teamRooms?.value?.data?.length !== 0) {
			postEmptyRooms(trustedSuppliers, 'team', teamRooms.value?.data);
		}

		if (teamState?.currentTeam?.teamInitials !== 'ME') {
			let filteredMembers = teamState?.currentTeam?.members.filter((member) => member.firstname);
			postEmptyRooms(filteredMembers, 'personal', personalRooms.value?.data);
		} else {
			setTabs((prevTabs) => {
				const newTabs = new Map(prevTabs);
				if (newTabs.get(2)) {
					newTabs.delete(2);
				}
				return newTabs;
			});
			setSearchParam({ key: 'tab', value: 1 });
			handleSelectTab(1);
		}

		if (searchParams.get('roomUuid')) {
			handleSelectRoom(searchParams.get('roomUuid'));
		}
		if (Number(searchParams.get('tab'))) {
			handleSelectTab(Number(searchParams.get('tab')));
		}
		if (chatBox.current) {
			chatBox.current.scrollTop = chatBox.current.scrollHeight;
		}
		socket.emit('join-room', selectedRoom);
		setLoaded(true);
	}, [chatRoomSearch]);

	useEffect(() => {
		if (rooms?.get(selectedRoom)?.messageSkip === 0) {
			scrollChatToBottom();
		}
	}, [selectedRoom]);

	useEffect(async () => {
		let newRooms = [];

		if (roomsSkip.order?.skip > 0 && roomsSkip.order?.roomsFetched === false) {
			let orderRoomsRes = await getTypeOfRooms(
				teamState?.currentTeam?.teamUuid,
				roomLimit,
				roomsSkip.order.skip,
				'order'
			);
			newRooms = orderRoomsRes.data;
			handleTotalRooms(orderRoomsRes, 'order');
			handleRoomSkip('order', true);
		} else if (roomsSkip.team?.skip > 0 && roomsSkip.team?.roomsFetched === false) {
			let teamRoomsRes = await getTypeOfRooms(
				teamState?.currentTeam?.teamUuid,
				roomLimit,
				roomsSkip.team.skip,
				'team'
			);
			newRooms = teamRoomsRes.data;
			handleTotalRooms(teamRoomsRes, 'team');
			handleRoomSkip('team', true);
		} else if (roomsSkip.personal?.skip > 0 && roomsSkip.personal?.roomsFetched === false) {
			let personalRoomsRes = await getTypeOfRooms(
				teamState?.currentTeam?.teamUuid,
				roomLimit,
				roomsSkip.personal.skip,
				'personal'
			);
			newRooms = personalRoomsRes.data;
			handleTotalRooms(personalRoomsRes, 'personal');
			handleRoomSkip('personal', true);

			if (teamState?.currentTeam?.teamInitials !== 'ME') {
				let filteredMembers = teamState?.currentTeam?.members.filter((member) => member.firstname);
				postEmptyRooms(filteredMembers, 'personal', newRooms);
			}
		}

		if (newRooms) {
			setRooms((rooms) => {
				const updatedRooms = new Map(rooms);

				newRooms.forEach((room) => {
					updatedRooms.set(room.roomUuid, room);
				});

				return updatedRooms;
			});
			queUpRoomsForPrep(newRooms);
		}
	}, [roomsSkip]);

	useEffect(async () => {
		let unpreppedRooms = false;

		if (roomsToPrep) {
			for (const [key, value] of roomsToPrep) {
				if (value.isPrepped === false) {
					unpreppedRooms = true;
				}
			}
		}

		if (roomsToPrep && unpreppedRooms) {
			setLoaded(false);
			let roomsCopy = new Map(rooms);
			let fullRooms = [];

			for (const [key, value] of roomsToPrep) {
				let room = roomsCopy.get(key);
				if (room && value.isPrepped === false) {
					fullRooms.push(room);
				}
			}

			fullRooms = await prepRooms(fullRooms);

			setRoomsToPrep((prevRoomsToPrep) => {
				const newRoomsToPrep = new Map(prevRoomsToPrep);
				for (const [key, value] of prevRoomsToPrep) {
					newRoomsToPrep.set(key, { isPrepped: true });
				}
				return newRoomsToPrep;
			});

			setRooms((prevRooms) => {
				const newRooms = new Map(prevRooms);
				fullRooms.forEach((room) => {
					newRooms.set(room.roomUuid, room);
				});
				if (newRooms) {
					return newRooms;
				} else {
					return prevRooms;
				}
			});
			setLoaded(true);
			return;
		}
	}, [roomsToPrep]);

	return (
		<TeamMessagingWrapper
			backColor='overviewGray'
			style={{
				flexDirection: 'column',
				alignItems: 'stretch',
				padding: width > breakpoints.tablet.max ? '24px' : '12px',
			}}
			ref={pageContainerRef}>
			<WrapperHeader />
			<div className='chatMainCont'>
				<ChatSelectColumn
					rooms={rooms}
					updateDebounceSearch={updateDebounceSearch}
					handleSelectRoom={handleSelectRoom}
					setSearchParam={setSearchParam}
					chatRoomSearch={chatRoomSearch}
					setSelectedTab={handleSelectTab}
					handleRoomSkip={handleRoomSkip}
					selectedRoom={selectedRoom}
					selectedTab={selectedTab}
					totalRooms={totalRooms}
					roomLimit={roomLimit}
					roomsSkip={roomsSkip}
					loaded={loaded}
					tabs={tabs}
				/>
				{rooms && selectedRoom != null ? (
					<ChatBox
						room={rooms?.get(selectedRoom)}
						inputFilled={inputFilled}
						loaded={loaded}
						handleChange={handleChange}
						handleScroll={handleScroll}
						handleNewMessage={handleNewMessage}
						messageFetchLoading={messageFetchLoading}
						ref={{ divEnd, message, chatBox }}
					/>
				) : (
					<MissingChatState />
				)}
			</div>
		</TeamMessagingWrapper>
	);

	async function getTypeOfRooms(teamUuid, roomLimit, roomSkip, roomType, search) {
		let teamRooms = await fetch(
			`${
				config.apiv1
			}/chat/rooms.read/${teamUuid}?messageLimit=${messageLimit}&roomLimit=${roomLimit}&roomSkip=${roomSkip}&type=${roomType}${
				search ? `&search=${search}` : ''
			}`,
			null,
			true
		);

		return teamRooms;
	}

	async function postNewRoom(data) {
		try {
			let newRoom = await post(
				{
					teamParticipants: data.teamParticipants,
					userParticipants: data.userParticipants,
					type: data.type,
				},
				`${config.apiv1}/chat/room.create`
			);

			return newRoom;
		} catch (err) {
			console.log(err);
		}
	}

	async function postEmptyRooms(entities, type, fetchedRooms) {
		let unmatchedEntities = [];

		if (type === 'team') {
			unmatchedEntities = entities.filter(
				(entity) =>
					!fetchedRooms?.some((room) =>
						room.teamParticipants.some((participant) => participant === entity.teamUuid)
					)
			);
		}
		if (type === 'personal') {
			unmatchedEntities = entities.filter(
				(entity) =>
					!fetchedRooms?.some((room) =>
						room.userParticipants.some((participant) => participant === entity.userUuid)
					)
			);
		}

		const reqList = [];

		unmatchedEntities.forEach(async (entity) => {
			if (entity.userUuid !== splitUserId(userState.currUser.sub)) {
				reqList.push(
					postNewRoom({
						type: type,
						teamParticipants:
							type === 'team'
								? [entity.teamUuid, teamState?.currentTeam?.teamUuid]
								: [teamState?.currentTeam?.teamUuid],
						userParticipants:
							type === 'personal' ? [entity.userUuid, splitUserId(userState.currUser.sub)] : [],
					})
				);
			}
		});

		let entityRooms = await Promise.allSettled(reqList);
		let parsedRooms = [];

		entityRooms.forEach((room) => {
			if (room.status === 'fulfilled' && room.value?.data) {
				parsedRooms.push(room.value?.data);
			}
		});

		handleNewRooms(parsedRooms);
		queUpRoomsForPrep(parsedRooms);
	}

	async function getRoom(roomUuid, type, messageSkip) {
		try {
			let room = await fetch(
				`${config.apiv1}/chat/room.read/${roomUuid}?teamUuid=${
					teamState?.currentTeam?.teamUuid
				}&type=${type}&messageSkip=${messageSkip || 0}&messageLimit=${messageLimit}`
			);
			if (room && room[0]) {
				return room[0];
			} else {
				return {};
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getTrustedTeams(teamUuid) {
		try {
			let trustedTeams = await fetch(`${config.apiv1}/team/team.read/trusted/${teamUuid}`);
			if (trustedTeams) {
				return trustedTeams;
			} else {
				return [];
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function patchChatToRead(roomUuid) {
		if (!roomUuid) {
			return false;
		}

		let res = await Axios({
			method: 'PATCH',
			url: `${config.apiv1}/chat/room.update/messages/${roomUuid}`,
		});
		if (res.data?.ok) {
			setRooms((rooms) => {
				const updatedRooms = new Map(rooms);

				const targetRoom = updatedRooms.get(roomUuid);
				if (targetRoom) {
					updatedRooms.set(roomUuid, {
						...targetRoom,
						unreadMessageCount: 0,
					});
				}

				return updatedRooms;
			});
		}
	}

	async function applyTeamOfOneUserData(team) {
		if (!team) return team;

		let teamOfOneUser = await fetch(`${config.auth}/v1/user/user.read/${team.teamInfo.admin}`, null, true);

		if (teamOfOneUser?.user) {
			teamOfOneUser = teamOfOneUser.user;
		}

		team = {
			...team,
			teamInfo: { ...team.teamInfo, teamName: formatUserName(teamOfOneUser) },
		};

		return team;
	}
}
