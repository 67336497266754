import React, { useContext } from 'react';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { getCurrDate } from '../../helpers';
import usePost from '../../hooks/usePost';
import PackageNewForm from '../Forms/PackageNewForm';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import useFetch from '../../hooks/useFetch';
import Button from '../Button';

export default function PackageNewModal(props) {
	const {
		setShow,
		setCustomErrors,
		setShowOverlay,
		setFormData,
		setPackages,
		formData,
		handleFocused,
		handleModal,
		focused,
		sellerAddresses,
		address,
		couriers,
		setCouriers,
		packages,
		addressForm,
		addresses,
		missingOptions,
		zIndex,
		handleFadeOutModal,
		isSeller,
	} = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const { data, post, reqLoading, error, customErrors } = usePost(
		`${config.apiv1}/package/package.create`,
		(data) => {
			if (data.ok) {
				handleModal('packageNewModal', false);
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'New package created successfully',
				});
				setPackages({ [data?.data?.packageUuid]: data?.data, ...packages }, data?.data?.packageUuid);
			} else {
				setCustomErrors({ ...customErrors, ...data?.customErrors });
			}
		},
		'packFormCont'
	);

	function createPackRef(courierUuid) {
		if (courierUuid) {
			let courier = couriers ? couriers.filter((courier) => courier.courierUuid === courierUuid) : [];
			let member = teamState.currentTeam.members
				? teamState.currentTeam.members.filter((member) => member.userUuid === courierUuid)
				: [];
			if (courier.length > 0) {
				let packRef = `${courier[0].companyName && courier[0].companyName}_${getCurrDate('DD-MM-YYYY_hh:mm')}${
					formData?.address?.name ? `_${formData?.address?.name}` : ''
				}`;
				return packRef;
			} else if (member.length > 0) {
				courierUuid = `${member[0].firstname}_${getCurrDate('DD-MM-YYYY-hh:mm')}${
					formData?.address?.name ? `_${formData?.address?.name}` : ''
				}`;
			} else {
				courierUuid = `${getCurrDate('DD-MM-YYYY_hh:mm')}${
					formData?.courierUuid ? `_${formData?.courierUuid}` : ''
				}${formData?.address?.name ? `_${formData?.address?.name}` : ''}`;
			}
		} else {
			courierUuid = `${getCurrDate('DD-MM-YYYY_hh:mm')}${
				formData?.courierUuid ? `_${formData?.courierUuid}` : ''
			}${formData?.address?.name ? `_${formData?.address?.name}` : ''}`;
		}
		return courierUuid;
	}

	const handlePackageNewData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	function handlePackageSelectData(data, fieldName) {
		let newData = {};
		setCustomErrors({});
		if (typeof data.value === 'object') {
			data = { ...data.value, teamName: data.name };
		} else {
			data = data.value;
		}

		if (fieldName === 'address' && data.teamUuid) {
			newData.recipientTeamUuid = data.teamUuid;
		}

		newData[fieldName] = data;

		setFormData((formData) => ({
			...formData,
			...newData,
		}));
	}

	const handleAddressInput = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			address: { ...formData.address, [name]: value },
		}));
	};

	async function postForm() {
		let origin = 0;
		if (isSeller !== null) {
			isSeller ? (origin = 3) : (origin = 1);
		}

		let packageData = {
			...formData,
			senderTeamUuid: teamState.currentTeam.teamUuid,
			packageRef: createPackRef(formData?.courierUuid || formData?.courierName),
			origin: origin,
		};

		if (formData?.courierUuid === 'other') {
			const courierData = {
				teamUuid: teamState.currentTeam.teamUuid,
				companyName: formData?.courierName,
			};

			let newCourier = await post(courierData, `${config.apiv1}/courier/courier.create`, false);

			setCouriers && setCouriers((couriers) => [...couriers, newCourier.data]);

			packageData.courierUuid = newCourier?.data?.courierUuid;
		}

		let newPack = await post(packageData);
		setFormData((formData) => ({ ...formData, package: { value: newPack?.data } }));
	}

	return (
		<>
			<div className='flexModal' style={{ zIndex: zIndex }}>
				{!reqLoading ? (
					<div className='mainFormCont' id='packFormCont'>
						<PackageNewForm
							handleFormInput={handlePackageNewData}
							handleAddressInput={handleAddressInput}
							handleFormSelectData={handlePackageSelectData}
							formData={formData}
							focused={focused}
							handleFocused={handleFocused}
							address={address}
							sellerAddresses={sellerAddresses}
							couriers={couriers}
							addressForm={addressForm}
							addresses={addresses}
							missingOptions={missingOptions}
							customErrors={customErrors || {}}
						/>
						<div className='btnCont modal reverse'>
							<Button text='Save' style='primary' type='lg' onClick={postForm} />
							<Button
								text='Close'
								style='secondary'
								type='lg'
								onClick={() => {
									setFormData ? setFormData({ quotedCollectionDateTime: new Date() }) : null;
									setShow ? setShow('packageNewModal', false) : null;
									setShowOverlay ? setShowOverlay(false) : null;
								}}
							/>
						</div>
					</div>
				) : (
					<>
						<Loading type='circle' />
					</>
				)}
			</div>
			<Overlay loadOverlay={true} showOverlay={true} zIndex={zIndex} />
		</>
	);
}
