import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	checkFileType,
	getCurrDate,
	splitUserId,
	checkStatusIfDisable,
	checkUnreadMessages,
	defaultDocketTemp,
	makeFirstCharUpper,
	parseProcessingStatus,
} from '../../helpers';

import { ReactComponent as ProcuurLoadingIcon } from '../../assets/svg/procuur-loading.svg';

import config from '../../config';
import Loading from '../../components/Loading/Loading';
import { useLocation, useParams } from 'react-router-dom';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SocketContext } from '../../App';
import { ChatIcon, CheckIcon, CircleSolidIcon, ProgressHoldIcon } from '../../components/SVGIcons/SVGIcons';
import OrderEditModal from '../../components/Modals/OrderEditModal';
import Overlay from '../../components/Overlay';
import ImgModal from '../../components/Modals/ImgModal';
import OrderItemNewModal from '../../components/Modals/OrderItemNewModal';
import PackageNewModal from '../../components/Modals/PackageNewModal';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import FlexModal from '../../components/Modals/FlexModal';
import AddToPackForm from '../../components/Forms/AddToPackForm';
import OrderCommentForm from '../../components/Forms/OrderCommentForm';
import usePost from '../../hooks/usePost';
import CourierNewForm from '../../components/Forms/CourierNewForm';
import STLViewer from '../../components/STLViewer/STLViewer';
import OrderGrid from './OrderGrid';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { Helmet } from 'react-helmet';
import metaFieldsByPage from '../../helmetMeta';
import Button from '../../components/Button';
import Badge from '../../components/Badge';
import useModals from '../../hooks/useModals';
import HoldOrderForm from '../../components/Forms/HoldOrderForm';
import ExternalAttachmentForm from '../../components/Forms/ExternalAttachmentForm';
import { v4 as uuidv4 } from 'uuid';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import { handleCreationError, handleResourceError } from '../../axiosErrorHandling';
import UploadModal from '../../components/Modals/UploadModal';
import DicomViewer from '../../components/DicomViewer';
import History from './History';
import { useModalPortal } from '../../contexts/ModalPortalContext';
import SubscriptionAdvertModal from '../../components/Modals/SubscriptionAdvertModal';

export default function Order() {
	const maxSize = 2000 * 1000 * 1000;
	const CHUNK_SIZE_OLD = 2 * 1024 * 1024;
	const CHUNK_SIZE = 100 * 1000;
	const fileLocation = 1;

	const [order, setOrder] = useState({});
	const [newExternalAttachment, setNewExternalAttachment] = useState([]);
	const [newOrderData, setNewOrderData] = useState({});
	const [newCommentData, setNewCommentData] = useState({});
	const [courierNewData, setCourierNewData] = useState({});
	const [parentOrder, setParentOrder] = useState({});
	const [packages, setPackages] = useState({});
	const [couriers, setCouriers] = useState([]);
	const [viewerOpen, setViewerOpen] = useState(true);
	const [currViewedFile, setCurrViewedFile] = useState(null);
	const [imgs, setImgs] = useState([]);
	const [externalAttachments, setExternalAttachments] = useState([]);
	const [generalFiles, setGeneralFiles] = useState([]);
	const [newFiles, setNewFiles] = useState([]);
	const [uploadProgress, setUploadProgress] = useState({});
	const [uploading, setUploading] = useState(false);
	const [completedFiles, setCompletedFiles] = useState([]);
	const [changeMade, setChangeMade] = useState(false);
	const [showPriceInput, setShowPriceInput] = useState(false);
	const [itemNewData, setItemNewData] = useState(null);
	const [chosenPackage, setChosenPackage] = useState(null);
	const [invoiceNewData, setInvoiceNewData] = useState({});
	const [packageNewData, setPackageNewData] = useState({
		quotedCollectionDateTime: Date.now(),
	});
	const [holdData, setHoldData] = useState(null);
	const [addresses, setAddresses] = useState([]);
	const [suggestedPrice, setSuggestedPrice] = useState(null);
	const [suggestedPriceStatus, setSuggestedPriceStatus] = useState(null);
	const [isSeller, setIsSeller] = useState(null);
	const [customErrors, setCustomErrors] = useState({});
	const [loaded, setLoaded] = useState(false);

	const [docketFields, setDocketFields] = useState({});
	const [changeReqFields, setChangeReqFields] = useState({});
	const [changeReqUuid, setChangeReqUuid] = useState(null);
	const [showOverlay, setShowOverlay] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [focused, setFocused] = useState({});

	const [dropdownOpen, setDropdownOpen] = useState({});
	const [modalOpen, setModalOpen] = useState({});
	const [expandedItems, setExpandedItems] = useState({});

	const [seller, setSeller] = useState(null);

	const [updatedFields, setUpdatedFields] = useState({});
	const [orderExternalAttachments, setOrderExternalAttachments] = useState({});

	const { modals, showModal, hideModal } = useModalsGlobal();

	const { flashFadeModal } = useModals();

	const pageTopRef = useRef(null);

	let params = useParams();
	let navigate = useNavigate();
	let location = useLocation();

	const { data, post, reqLoading, error } = usePost(
		`${config.apiv1}/comment/comment.create`,
		(data) => {
			setUpdateLoading(false);
			if (data.ok) {
				handleModal('commentModal', false);
				setNewCommentData({});
				setOrder({
					...order,
					comments: [...order.comments, data?.data],
				});
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Order comment successfully added',
				});
			} else {
				setCustomErrors(data.customErrors);
			}
		},
		'commentForm'
	);

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	const { userState, userDispatch } = useContext(CurrentUserContext);
	const { notificationsState } = useContext(NotificationContext);
	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	const socket = useContext(SocketContext);

	const { openModalPortal, closeModalPortal } = useModalPortal();

	const handleOpenModalPortal = () => {
		openModalPortal(
			<SubscriptionAdvertModal
				subscriptionLevelName={'Business'}
				featureName={'order allocation'}
				close={closeModalPortal}
			/>,
			pageTopRef.current
		);
	};

	const handleFiles = (e) => {
		if (!changeMade) {
			setChangeMade(true);
		}
		let files = [];
		for (let i = 0; i < e.target.files.length; i++) {
			files.unshift(e.target.files[i]);
		}
		setNewFiles(files);
	};

	function handleDropdown(name, value) {
		setDropdownOpen((dropdownOpen) => ({
			...dropdownOpen,
			[name]: value,
		}));
	}

	function handleModal(name, value) {
		if (value) {
			pageTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		setModalOpen((modalOpen) => ({
			...modalOpen,
			[name]: value,
		}));
	}

	function handleInvoiceNewData(value, name) {
		setCustomErrors({});
		setInvoiceNewData((invoiceNewData) => ({
			...invoiceNewData,
			[name]: value,
		}));
	}

	function handleHoldData(value, name) {
		setCustomErrors({});
		setHoldData((holdData) => ({
			...holdData,
			[name]: value,
		}));
	}

	function handleHoldSelectData(data, fieldName) {
		setCustomErrors({});
		setHoldData((holdData) => ({
			...holdData,
			[fieldName]: data.value,
		}));
	}

	function handleItemNewData(value, name) {
		setCustomErrors({});
		setItemNewData((itemNewData) => ({
			...itemNewData,
			[name]: value,
		}));
	}

	function handleItemNewSelectData(data, fieldName) {
		setCustomErrors({});
		setItemNewData((itemNewData) => ({
			...itemNewData,
			[fieldName]: data.value,
		}));
	}

	function handleNewOrderData(value, name) {
		setCustomErrors({});
		setNewOrderData((newOrderData) => ({
			...newOrderData,
			[name]: value,
		}));
	}

	function handleNewCommentData(value, name) {
		setCustomErrors({});
		setNewCommentData((newCommentData) => ({
			...newCommentData,
			[name]: value,
		}));
	}

	function handleFormSelectData(data, fieldName) {
		setCustomErrors({});
		setNewOrderData((formData) => ({
			...formData,
			[fieldName]: data.value,
		}));
	}

	function handleChosenPackSelectData(data) {
		setCustomErrors({});
		setChosenPackage(data);
	}

	const handleFocused = (e) => {
		const { name } = e.target;
		setFocused((focused) => ({
			...focused,
			[name]: !focused[name],
		}));
	};

	const handleCourierNewData = (value, name) => {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			[name]: value,
		}));
	};

	const handleExternalAttachmentData = (value, name) => {
		setCustomErrors({});
		setNewExternalAttachment((externalAttachmentData) => ({
			...externalAttachmentData,
			[name]: value,
		}));
	};

	const handleCourierAddressInput = (value, name) => {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			['address']: { ...courierNewData.address, [name]: value },
		}));
	};

	function handleCourierSelectData(data, fieldName) {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			[fieldName]: data.value,
		}));
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	const handlePackages = (packages, newPackUuid) => {
		setPackages(packages);
		setItemNewData((itemNewData) => ({
			...itemNewData,
			packageUuid: newPackUuid,
		}));
	};

	const handleNewFiles = (files, name) => {
		if (!files?.length) {
			files = [files];
		}
		setNewFiles(() => [...newFiles, ...files]);
	};

	function leaveRoom() {
		socket.emit('leave-room', order.orderUuid);
	}

	const uploadChunk = async (chunk, index, filename, chunksDirUuid) => {
		const formData = new FormData();
		formData.append('teamUuid', teamState.currentTeam.teamUuid);
		formData.append('fileAttachmentType', fileLocation);
		formData.append('chunkIndex', index);
		formData.append('filename', filename);
		formData.append('chunksDirUuid', chunksDirUuid);
		formData.append('chunk', chunk);

		await Axios.post(`${config.apiv1}/file/chunk.create`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	async function postFiles() {
		setUploading(true);
		for (let i = 0; i < newFiles?.length; i++) {
			if (!newFiles[i]) return;

			const chunksDirUuid = uuidv4();
			const file = newFiles[i];
			const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
			const filename = file.name?.split('.')[0];
			const mimetype = file.name?.split('.')[1];
			let uploadedChunks = 0;

			for (let i = 0; i < totalChunks; i++) {
				const start = i * CHUNK_SIZE;
				const end = Math.min(start + CHUNK_SIZE, file.size);
				const chunk = file.slice(start, end);

				await uploadChunk(chunk, i, filename, chunksDirUuid);
				uploadedChunks += 1;
				let progressPercent = Math.round((uploadedChunks / totalChunks) * 100);
				setUploadProgress((files) => ({
					...files,
					[file.name]: {
						percent: progressPercent,
						complete: progressPercent >= 100,
					},
				}));
			}
			await Axios.post(`${config.apiv1}/file/chunks.merge`, {
				filename,
				totalChunks,
				chunksDirUuid,
				mimetype,
				fileAttachmentType: 1,
				orderUuid: order.orderUuid,
				teamUuid: teamState.currentTeam.teamUuid,
				dupeTeamUuid: isSeller ? order?.buyerTeamUuid : order?.sellerTeamUuid,
				originalname: file.name,
				size: file.size,
				notificationTargetTeamUuid: isSeller ? order?.buyerTeamUuid : order?.sellerTeamUuid,
			});
		}
		setCompletedFiles((completedFiles) => [...newFiles, ...completedFiles]);
		setNewFiles([]);
		setUploading(false);

		handleFadeOutModal({
			show: true,
			isFading: false,
			message: 'New file(s) successfully uploaded',
		});
	}

	async function removeFile(file) {
		let newArr = newFiles;
		let i = newArr.indexOf(file);
		newArr.splice(i, i + 1);
		setNewFiles((newArr) => [...newArr]);
		if (!newFiles[0]) {
			setChangeMade(false);
		}
	}

	async function createImgUrls(files) {
		if (!files || files?.length === 0) {
			return;
		}
		async function addRequest(url) {
			return await Axios({
				url: url,
				method: 'GET',
				responseType: 'blob',
			});
		}

		const imgs = [];
		if (files.length > 0) {
			let reqs = [];
			let parsedFiles = files.filter((file) => checkFileType(file?.filename?.split('.')[1]) === 1);
			for (let i = 0; i < parsedFiles.length; i++) {
				reqs.push(addRequest(`${config.api}/file?path=${parsedFiles[i].path}`));
			}
			if (reqs.length > 0) {
				try {
					await Axios.all(reqs).then(
						Axios.spread((...responses) => {
							for (let i = 0; i < responses.length; i++) {
								if (responses[i].data) {
									const url = window.URL.createObjectURL(new Blob([responses[i].data]));
									let file = parsedFiles[i];
									file.url = url;
									imgs.push(file);
								}
							}
						})
					);
				} catch (err) {
					return err;
				}
			}
		}
		setLoaded(true);
		setImgs(() => {
			return imgs;
		});
	}

	async function postCourierNew() {
		setUpdateLoading(true);
		function checkInvalidFields() {
			let form = document.getElementById('courierForm');
			const elements = form.querySelectorAll('[isrequired]');
			for (let i = 0; i < elements.length; i++) {
				if (!courierNewData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					return true;
				}
			}
		}

		let invalid = checkInvalidFields();

		if (invalid) {
			setUpdateLoading(false);
			return;
		}
		const data = {
			accountNumber: courierNewData?.accountNmbr,
			teamUuid: teamState.currentTeam.teamUuid,
			telephone: courierNewData?.telephone,
			companyName: courierNewData?.name,
			notes: courierNewData?.notes,
			email: courierNewData?.email,
			address: courierNewData?.address,
		};

		try {
			const res = await Axios({
				url: `${config.apiv1}/courier/courier.create`,
				method: 'POST',
				data: data,
			});

			if (res.data?.ok) {
				setTimeout(() => {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'New courier created successfully',
					});
					setUpdateLoading(false);
					handleModal('courierNewModal', false);
					setCouriers([...couriers, res.data.data]);
					setCourierNewData({});
				}, 500);
			}
		} catch (err) {
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	async function addToPack() {
		try {
			const res = await Axios({
				url: `${config.apiv1}/order/item.update/${modalOpen['addToPackModal'].item.itemUuid}`,
				method: 'PATCH',
				data: { packageUuid: chosenPackage.value },
			});
			if (res.data?.ok) {
				let itemArr = order.items;
				let itemIndex = order.items
					.map((orderItem) => orderItem.itemUuid)
					.indexOf(modalOpen['addToPackModal'].item.itemUuid);
				itemArr[itemIndex] = res.data.data;
				setOrder({
					...order,
					['items']: itemArr,
				});
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Order item successfully added to package',
				});
				setUpdateLoading(false);
				handleModal('addToPackModal', { open: false, value: null });
			}
		} catch (err) {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when trying to add order item to package',
			});
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	async function removeItem(itemUuid) {
		try {
			const res = await Axios({
				url: `${config.apiv1}/order/item.delete/${itemUuid}`,
				method: 'DELETE',
			});
			if (res.data?.ok) {
				let itemArr = order.items;
				let itemIndex = order.items.map((orderItem) => orderItem.itemUuid).indexOf(itemUuid);
				itemArr.splice(itemIndex);
				setOrder({
					...order,
					items: itemArr,
				});
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Order item successfully removed',
				});
				setUpdateLoading(false);
				handleModal('addToPackModal', { open: false, value: null });
			}
		} catch (err) {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when trying to remove order item',
			});
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	function checkUserTeams(orderTeams) {
		let includedTeam = teamState.userTeams.filter((team) => orderTeams.includes(team.teamUuid));
		if (includedTeam.length > 0) {
			return includedTeam[0];
		} else {
			return false;
		}
	}

	async function patchDentallyAppointmentNotes(appointments, noteAppendage) {
		async function getDentallyIntegration(integrationUuid) {
			try {
				let res = await Axios({
					method: 'GET',
					url: `${config.apiv1}/integration/integration.read/${integrationUuid}`,
				});
				if (res.data && res.data.data) {
					return res.data.data;
				}
			} catch (err) {
				console.log(err);
			}
		}

		try {
			let dentallyIntegration = await getDentallyIntegration(teamState.currentTeam?.integrations?.dentally);

			for (let i = 0; i < appointments.length; i++) {
				let res = await Axios({
					url: `${config.dentallyAPI}/v1/appointments/${appointments[i]?.id}`,
					method: 'GET',
					headers: { Authorization: `Bearer ${dentallyIntegration?.token}` },
				});
				await Axios({
					url: `${config.dentallyAPI}/v1/appointments/${appointments[i]?.id}`,
					method: 'PUT',
					headers: { Authorization: `Bearer ${dentallyIntegration?.token}` },
					data: {
						appointment: {
							notes: `${res?.data?.appointment?.notes},\n${noteAppendage}`,
						},
					},
				});
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function patchOrder(data, onlyFiles, silent) {
		let formData;
		if (!data) {
			formData = new FormData();
			if (onlyFiles) {
				formData.append('onlyFiles', 1);
			}
			if (isSeller) {
				formData.append('sellerTeamUuid', teamState.currentTeam.teamUuid);
			} else {
				formData.append('buyerTeamUuid', teamState.currentTeam.teamUuid);
			}
			for (let key in newOrderData) {
				formData.append(key, newOrderData[key]);
			}
			if (newFiles.length > 0) {
				formData.append('fileRef', order?.orderUuid);
				for (let i = 0; i < newFiles.length; i++) {
					formData.append('files', newFiles[i]);
				}
			}
		}

		try {
			const res = await Axios({
				url: `${config.apiv1}/order/order.update/${order?.orderUuid}?${silent ? 'silent=true' : ''}`,
				method: 'PATCH',
				data: formData || { ...data, onlyFiles: onlyFiles ? 1 : 0 },
			});
			if (res.data?.newFields) {
				let dentallyAppointments = order?.integrations?.soe?.dentally?.appData?.appointments || [];
				if (data?.orderProcessingStatus && dentallyAppointments?.length !== 0) {
					patchDentallyAppointmentNotes(
						dentallyAppointments,
						`Procuur ${order.orderRef} (${parseProcessingStatus(data.orderProcessingStatus)?.text})`
					);
				}
				let updatedOrder = { ...order, ...res.data.newFields };

				setOrder(updatedOrder);
				createImgUrls(updatedOrder?.files);
				handleGeneralFiles(updatedOrder);
				setUpdateLoading(false);
				handleModal('editModal', false);
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Order successfully updated',
				});
				setChangeMade(false);
				if (res.data.newFields.totalPrice) {
					setSuggestedPriceStatus('accepted');
				}
				if (newFiles.length > 0) {
					setNewFiles([]);
				}
				if (parseInt(res.data.newFields.orderProcessingStatus) === 4) {
					handleModal('confirmModal', {
						value: true,
						header: 'Would you like to send the final product to the buyer?',
						callback: () => {
							handleModal('orderItemNewModal', true);
							handleModal('confirmModal', false);
							setPackageNewData({
								address: order.shippingAddress,
							});
						},
					});
				}
			}
		} catch (err) {
			if (err.response?.data?.error === 'SUBSCRIPTION_LEVEL_TOO_LOW') {
				handleOpenModalPortal();
			} else {
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Error when trying to update order',
				});
				handleCreationError(err, navigate, showModal);
			}
		}
	}

	async function postItemNew() {
		const data = {
			orderUuid: order?.orderUuid,
			note: itemNewData.note,
			packageUuid: itemNewData.packageUuid,
			teamUuid: teamState.currentTeam.teamUuid,
			itemDesc: itemNewData.itemDesc,
			orderRef: order?.orderRef,
		};

		try {
			const res = await Axios({
				url: `${config.apiv1}/order/item.create`,
				method: 'POST',
				data: data,
			});

			if (res.data?.ok) {
				let newArr = [res.data.data, ...order.items];
				setOrder((order) => ({
					...order,
					items: newArr,
				}));
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'New order item added successfully',
				});
				setUpdateLoading(false);
				handleModal('orderItemNewModal', false);
			}
		} catch (err) {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when trying to add order item',
			});
			handleCreationError(err, navigate, showModal);
		}
	}

	async function toggleHidden(file, filetype) {
		// Toggles the model viewed for STL between hidden and not hidden
		// in addition to fetching file from server
		switch (filetype) {
			case 'stl':
				if (file) {
					Axios({
						url: `${config.api}/file?path=${file.path}`,
						method: 'GET',
						responseType: 'blob',
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						setCurrViewedFile({ url: url, name: file.originalname });
						setViewerOpen(viewerOpen === filetype ? '' : filetype);
					});
				} else {
					setCurrViewedFile(null);
					setViewerOpen(viewerOpen === filetype ? '' : filetype);
				}
				break;
			case 'dcm':
				if (file) {
					setCurrViewedFile(file);
					setViewerOpen(viewerOpen === filetype ? '' : filetype);
				} else {
					setCurrViewedFile(null);
					setViewerOpen(viewerOpen === filetype ? '' : filetype);
				}
		}
	}

	function downloadFile(file) {
		Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.originalname || file.filename);
			document.body.appendChild(link);
			link.click();
		});
	}

	function fillAndOpenRightSidebar(boxRef) {
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: order });
		rightSidebarDispatch({
			type: 'SET_EXIT_CALLBACK',
			payload: () => {
				leaveRoom();
			},
		});
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 1 });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
		rightSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: { boxRef: boxRef, boxOpen: true },
		});
	}

	function checkSuggestedPrice(orderUuid) {
		// Check if there currently is a notification with a suggested price for order from seller
		Axios({
			url: `${config.apiv1}/notification/notifications.read/${
				teamState.currentTeam.teamUuid
			}?limit=1&sortDate=desc&type=${'order.priceSuggested'}&orderUuid=${orderUuid}`,
			method: 'GET',
		}).then((response) => {
			if (response?.data?.data) {
				setSuggestedPrice(response.data.data[0]);
				setSuggestedPriceStatus(response.data.data[0]?.notificationData?.isAccepted);
			}
		});
	}

	function handleGeneralFiles(order) {
		let generalFiles = [];
		for (let i = 0; i < order?.files?.length; i++) {
			if (checkFileType(order?.files[i]?.filename?.split('.')[1]) === 2) {
				generalFiles.push(order?.files[i]);
			}
		}
		generalFiles.reverse();
		setGeneralFiles(generalFiles);
	}

	function suggestPrice() {
		// Suggest price to buyer
		socket.emit('suggest-price', {
			price: newOrderData.totalPrice,
			orderUuid: order?.orderUuid,
			targetTeamUuid: order?.buyerTeamUuid,
			senderTeamUuid: teamState.currentTeam.teamUuid,
			orderRef: order?.orderRef,
		});
		handleFadeOutModal({
			show: true,
			isFading: false,
			message: 'Price suggestion sent',
		});
	}

	function updateNotificationStatus(data) {
		Axios({
			url: `${config.apiv1}/notification/notification.update/${suggestedPrice.notificationUuid}`,
			method: 'PATCH',
			data: {
				'notificationData.isAccepted': data,
			},
		})
			.then((response) => {
				if (response?.data) {
					if (response.data.err) {
						console.log(response?.data?.err);
					}
					if (response.data.ok) {
						if (data === 'declined') {
							socket.emit('decline-price', {
								price: newOrderData.totalPrice,
								orderUuid: order?.orderUuid,
								targetTeamUuid: order?.sellerTeamUuid,
								senderTeamUuid: teamState.currentTeam.teamUuid,
								orderRef: order?.orderRef,
							});
							return;
						}
						setOrder({
							...order,
							['totalPrice']: newOrderData.totalPrice,
						});
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Order successfully updated',
						});
					}
				} else {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Error when trying to update order',
					});
				}
			})
			.catch((err) => {
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Error when trying to update order',
				});
				handleCreationError(err, navigate, showModal);
			});
	}

	const getPackages = (order) => {
		if (!order?.orderUuid || order?.items?.length < 1) return;

		try {
			Axios({
				url: `${config.apiv1}/package/packages.read/order/${order.orderUuid}`,
				method: 'GET',
			})
				.then((response) => {
					if (response?.data?.data?.length !== 0) {
						setPackages(
							response?.data?.data
								.reverse()
								.reduce((arr, value) => ({ ...arr, [value.packageUuid]: value }), {})
						);
					}
				})
				.catch((err) => {
					return err;
				});
		} catch (err) {
			if (err?.response?.status === 401) {
				window.location.reload();
			}
			return err;
		}
	};

	const getCouriers = () => {
		try {
			Axios({
				url: `${config.apiv1}/courier/couriers.read/${teamState.currentTeam.teamUuid}`,
				method: 'GET',
			})
				.then((response) => {
					if (response?.data?.data) {
						setCouriers(response?.data?.data);
					}
				})
				.catch((err) => {
					return err;
				});
		} catch (err) {
			return err;
		}
	};

	const getAddresses = (data, isSeller) => {
		const teamUuid = isSeller ? data.buyerTeamUuid : data.sellerTeamUuid;
		try {
			Axios({
				url: `${config.apiv1}/team/team.read/${teamUuid}`,
				method: 'GET',
			})
				.then((response) => {
					console.log(response);
					if (response?.data?.data) {
						for (let i = 0; i < response?.data?.data?.teamInfo?.addresses.length; i++) {
							response.data.data.teamInfo.addresses[i].teamUuid = teamUuid;
						}
						setSeller(response?.data?.data);
						setAddresses(response?.data?.data?.teamInfo?.addresses);
						setLoaded(true);
					}
				})
				.catch((err) => {
					return err;
				});
		} catch (err) {
			return err;
		}
	};

	function checkInvalidFields(formId) {
		let invalid = false;
		if (formId) {
			let elements = document.querySelectorAll('[isrequired]');
			let form = document.getElementById(formId);
			if (!form) {
				return invalid;
			}
			elements = form.querySelectorAll('[isrequired]');
			for (let i = 0; i < elements.length; i++) {
				if (!formData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					return (invalid = true);
				}
			}
		}
		return invalid;
	}

	function addExternalAttachment() {
		let invalid = checkInvalidFields('externalAdd');

		if (invalid) {
			return;
		}

		let tempUuid = uuidv4();

		setOrderExternalAttachments((files) => ({
			...files,
			[tempUuid]: {
				extFileUuid: tempUuid,
				service: newExternalAttachment?.service,
				folderPathName: newExternalAttachment?.folderPathName || undefined,
				recipientEmail: newExternalAttachment?.recipientEmail || undefined,
				senderEmail: newExternalAttachment?.senderEmail || undefined,
				accountName: newExternalAttachment?.accountName || undefined,
				scanDate: newExternalAttachment?.scanDate || undefined,
				fileName: newExternalAttachment?.fileName || undefined,
				subject: newExternalAttachment?.subject || undefined,
				date: newExternalAttachment?.date || undefined,
				note: newExternalAttachment?.note || undefined,
			},
		}));

		setNewExternalAttachment(() => ({ service: newExternalAttachment?.service }));
	}

	function deleteExternalAttachment(attachmentUuid) {
		if (attachmentUuid) {
			let newExternalAttachments = { ...orderExternalAttachments };
			delete newExternalAttachments[attachmentUuid];
			setOrderExternalAttachments(newExternalAttachments);
		}
	}

	async function postExternalAttachments() {
		let newExternalAttachments = [];
		let reqs = [];

		async function addRequest(url, attachment) {
			return await Axios({
				url: url,
				method: 'POST',
				data: {
					teamUuid: teamState?.currentTeam?.teamUuid,
					serviceUuid: attachment.service?.serviceUuid,
					userUuid: splitUserId(userState.currUser.sub),
					orderUuid: order?.orderUuid,
					fields: {
						folderPathName: attachment.folderPathName || undefined,
						recipientEmail: attachment.recipientEmail || undefined,
						senderEmail: attachment.senderEmail || undefined,
						accountName: attachment.accountName || undefined,
						scanDate: attachment.scanDate || undefined,
						fileName: attachment.fileName || undefined,
						subject: attachment.subject || undefined,
						date: attachment.date || undefined,
						note: attachment.note || undefined,
					},
				},
			});
		}

		for (let attachment in orderExternalAttachments) {
			reqs.push(
				addRequest(
					`${config.apiv1}/externalAttachment/externalAttachment.create`,
					orderExternalAttachments[attachment]
				)
			);
		}

		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(
					Axios.spread(async (...responses) => {
						for (let i = 0; i < responses.length; i++) {
							newExternalAttachments.push(responses[i]?.data?.data);
						}
					})
				);
			} catch (err) {
				return err;
			}

			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'External attachments were added successfully!',
			});
			handleModal('externalAttachmentModal', null);
			setNewExternalAttachment({});
			setOrderExternalAttachments([]);
			return setExternalAttachments((externalAttachments) => [...newExternalAttachments, ...externalAttachments]);
		} else {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'There was an error when trying to uploading the external attachments',
			});
			return false;
		}
	}

	const getExternalAttachments = (data) => {
		try {
			Axios({
				url: `${config.apiv1}/externalAttachment/externalAttachment.read/order/${data?.orderUuid}`,
				method: 'GET',
			})
				.then((response) => {
					if (response?.data?.data) {
						setExternalAttachments(response?.data?.data?.reverse());
					}
				})
				.catch((err) => {
					return err;
				});
		} catch (err) {
			// if (err?.response?.status === 401) {
			// 	window.location.reload();
			// }
			return err;
		}
	};

	async function getChangeReqDocket(supplierUuid) {
		const res = await Axios({
			url: `${config.apiv1}/docket/dockets.read/${supplierUuid}`,
			method: 'GET',
		});
		if (res?.data?.ok) {
			if (res.data.data && Array.isArray(res.data.data)) {
				let childExists = res.data?.data.filter(
					(docket) => order.customFields?.docketUuid && docket.parentUuid === order.customFields.docketUuid
				);
				let defaultChangeReq = res.data?.data.filter(
					(docket) => docket.default === true && docket.docketType === 2
				);
				if (childExists && childExists?.length > 0) {
					let parsedFields = parseDocketFields(childExists);
					setChangeReqFields(parsedFields);
					setChangeReqUuid(childExists[0]?.docketUuid);
				} else if (defaultChangeReq && defaultChangeReq?.length > 0) {
					let parsedFields = parseDocketFields(defaultChangeReq);
					setChangeReqFields(parsedFields);
					setChangeReqUuid(defaultChangeReq[0]?.docketUuid);
				} else {
					setChangeReqUuid(null);
				}

				let originalDocket = res.data?.data.filter(
					(docket) => order.customFields?.docketUuid === docket.docketUuid
				);
				if (originalDocket && originalDocket?.length > 0) {
					let parsedFields = parseDocketFields(originalDocket);
					setDocketFields(parsedFields);
				} else {
					let defaultDocket = defaultDocketTemp(teamState?.currentTeam);
					let parsedFields = parseDocketFields(defaultDocket);
					setDocketFields(parsedFields);
				}

				function parseDocketFields(docket) {
					if (docket && docket[0]) {
						docket = docket[0];
					}
					let parsedFields = {};
					for (let i = 0; i < docket?.fields?.length; i++) {
						if (docket?.fields[i]?.data?.fieldName) {
							parsedFields[docket?.fields[i]?.data?.fieldName] = {
								fieldText: docket?.fields[i]?.data?.fieldText,
								timeSelect: docket?.fields[i]?.data?.timeSelect,
							};
						}
					}
					return parsedFields;
				}
			}
		}
	}

	async function markHoldAsDone() {
		let newHoldData = { ...order.hold, confirmationRequired: { isDone: true } };
		let res = await Axios({
			url: `${config.apiv1}/order/order.update/${order?.orderUuid}`,
			data: {
				buyerTeamUuid:
					teamState?.currentTeam?.teamUuid === order?.buyerTeamUuid ? teamState?.currentTeam?.teamUuid : null,
				sellerTeamUuid:
					teamState?.currentTeam?.teamUuid === order?.sellerTeamUuid
						? teamState?.currentTeam?.teamUuid
						: null,
				hold: newHoldData,
			},
			method: 'PATCH',
		});

		if (res?.data?.ok) {
			setOrder((order) => ({ ...order, hold: newHoldData }));
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: `A notification has been sent to ${order.sellerTeamName} informing about the hold being marked as done`,
			});
		} else {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'There was an error when updating the hold',
			});
		}
		setUpdateLoading(false);
	}

	async function updateOrderHold(remove, data = {}) {
		let newHoldData = {};

		if (holdData && remove !== true) {
			newHoldData = {
				teamUuid: teamState?.currentTeam?.teamUuid,
				holdIssuer: splitUserId(userState.currUser.sub),
				holdDescription: holdData?.holdDescription,
				private: holdData?.private,
				confirmationRequired:
					!holdData?.private && holdData?.confirmationRequired === true ? { isDone: false } : null,
				dateTimeSet: new Date(),
				reason: holdData?.reason,
				isHold: true,
				...data,
			};
		}
		let res = await Axios({
			url: `${config.apiv1}/order/order.update/${order?.orderUuid}?${
				holdData?.private === true ? 'silent=true' : ''
			}`,
			data: {
				buyerTeamUuid:
					teamState?.currentTeam?.teamUuid === order?.buyerTeamUuid ? teamState?.currentTeam?.teamUuid : null,
				sellerTeamUuid:
					teamState?.currentTeam?.teamUuid === order?.sellerTeamUuid
						? teamState?.currentTeam?.teamUuid
						: null,
				hold: newHoldData,
			},
			method: 'PATCH',
		});

		if (res?.data?.ok) {
			setOrder((order) => ({ ...order, hold: newHoldData }));
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: `Order was successfully put ${newHoldData ? 'on' : 'off'} hold${
					holdData?.private !== true ? ' and the buyer has been informed via notification' : ''
				}`,
			});
		} else {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'There was an error updating the order hold status',
			});
		}
		handleModal('holdModal', null);
		setHoldData({});
		setUpdateLoading(false);
	}

	const getOrderInfo = async () => {
		if (userState?.isAuthenticated && teamState?.currentTeam?.teamUuid) {
			try {
				let orderData = await Axios({
					url: `${config.apiv1}/order/order.read/${params.orderUuid}`,
					method: 'GET',
					responseType: 'stream',
				})
					.then((res) => {
						const data = res?.data?.data;
						if (!res?.data?.ok) {
							return navigate('/');
						}
						if (
							teamState?.currentTeam?.teamUuid === data.sellerTeamUuid ||
							teamState?.currentTeam?.teamUuid === data.buyerTeamUuid
						) {
							const isSeller = data?.sellerTeamUuid === teamState.currentTeam.teamUuid;
							data.items?.reverse();
							setOrder(data);
							setIsSeller(isSeller);
							handleGeneralFiles(data);
							getPackages(data);
							getCouriers();
							getAddresses(data, isSeller);
							getExternalAttachments(data);
							if (!data.totalPrice) {
								checkSuggestedPrice(data?.orderUuid);
							}
							if (data.files?.length > 0) {
								data.files.reverse();
								createImgUrls(data?.files);
							} else {
								setLoaded(true);
							}
							return data;
						} else {
							let teamPresent = checkUserTeams([data.sellerTeamUuid, data.buyerTeamUuid]);
							if (teamPresent) {
								return handleModal('redirectModal', {
									open: true,
									header: 'This order does not belong to your currently selected team. Do you wish to switch to the relevant team to view this order?',
									callback: () =>
										teamDispatch({
											type: 'SET_TEAM',
											payload: { teamUuid: teamPresent.teamUuid, hardReload: true },
										}),
								});
							} else {
								navigate('/');
							}
						}
					})
					.catch((err) => {
						handleResourceError(err, navigate, showModal);
					});
				return { ...orderData };
			} catch (err) {
				navigate('/');
			}
		} else {
			navigate('/');
		}
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			getOrderInfo();
		}

		return () => (mounted = false);
	}, [params.orderUuid]); // eslint-disable-next-line

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			async function getParentOrder() {
				let res = await Axios({
					url: `${config.apiv1}/order/order.read/${order?.parentUuid}`,
					method: 'GET',
					responseType: 'stream',
				});
				if (res.data?.ok) {
					setParentOrder(res.data.data);
				}
			}
			if (order.parentUuid) {
				getParentOrder();
			}
		}

		return () => (mounted = false);
	}, [order]);

	useEffect(() => {
		const openFocus = new URLSearchParams(location.search).get('openFocus');
		if (loaded && openFocus) {
			fillAndOpenRightSidebar(openFocus);
		}
	}, [loaded]); // eslint-disable-next-line

	if (Number(order?.orderProcessingStatus) === 0 && isSeller) {
		navigate('/');
	}

	useEffect(() => {
		getChangeReqDocket(order?.sellerTeamUuid);
	}, [order]);

	useEffect(() => {
		const updateOrderFromNotification = async (notification) => {
			if (
				notification?.notificationType === 'order.updated' &&
				notification.notificationData?.orderUuid === order?.orderUuid
			) {
				let oldOrder = { ...order };
				let newOrderData = await getOrderInfo();

				for (let key in newOrderData) {
					if (newOrderData[key] === oldOrder[key]) {
						delete newOrderData[key];
					}
				}

				setUpdatedFields(() => newOrderData);
				setTimeout(() => {
					setUpdatedFields(() => ({}));
				}, 1250);
			}
		};

		const updateOrder = async (notification) => {
			if (notification.notificationData?.orderUuid === order?.orderUuid) {
				let oldOrder = { ...order };
				let newOrderData = await getOrderInfo();

				for (let key in newOrderData) {
					if (newOrderData[key] === oldOrder[key]) {
						delete newOrderData[key];
					}
				}

				setUpdatedFields(() => newOrderData);
				setTimeout(() => {
					setUpdatedFields(() => ({}));
				}, 1250);
			}
		};

		if (socket) {
			socket.on('new-notification', updateOrderFromNotification);
			socket.on('order-update', updateOrder);
		}
		return () => {
			socket.off('new-notification', updateOrderFromNotification);
			socket.off('order-update', updateOrder);
		};
	}, [socket, order]);

	useEffect(() => {
		const init = async () => {
			await postFiles();
			getOrderInfo();
		};
		if (newFiles?.length !== 0) {
			init();
		}
	}, [newFiles]);

	return (
		<>
			{viewerOpen === 'stl' && (
				<>
					<Overlay loadOverlay={true} showOverlay={true} zIndex={99} />
					<STLViewer
						file={currViewedFile}
						close={() => toggleHidden(null, 'stl')}
						hidden={viewerOpen !== 'stl'}
					/>
				</>
			)}
			{viewerOpen === 'dcm' && (
				<>
					<Overlay loadOverlay={true} showOverlay={true} zIndex={99} />
					<DicomViewer file={currViewedFile} close={() => toggleHidden(null, 'dcm')} />
				</>
			)}
			<FadeOutModal
				show={modalOpen['modalFading']?.show}
				isFading={modalOpen['modalFading']?.isFading}
				message={modalOpen['modalFading']?.message}
			/>
			{modalOpen?.uploadModal?.open && (
				<>
					<Overlay
						loadOverlay={true}
						showOverlay={true}
						setShowOverlay={() => {
							handleModal('uploadModal', { open: false });
							setCompletedFiles([]);
						}}
					/>
					<UploadModal
						uploadProgress={uploadProgress}
						handleNewFiles={handleNewFiles}
						completedFiles={completedFiles}
						fileLocation={fileLocation}
						handleModal={handleModal}
						removeFile={removeFile}
						maxFileSize={maxSize}
						postFiles={postFiles}
						uploading={uploading}
						files={newFiles}
					/>
				</>
			)}
			{modalOpen.redirectModal?.open === true && (
				<>
					<ConfirmModal
						value={modalOpen['redirectModal'].value}
						header={modalOpen['redirectModal'].header}
						callback={modalOpen['redirectModal'].callback}
						handleModal={() => {
							handleModal();
							navigate('/');
						}}
						modalType={'redirectModal'}
						loading={updateLoading}
						zIndex={999}
					/>
				</>
			)}
			{modalOpen.confirmModal?.value === true && (
				<>
					<ConfirmModal
						value={modalOpen['confirmModal'].value}
						header={modalOpen['confirmModal'].header}
						callback={modalOpen['confirmModal'].callback}
						handleModal={handleModal}
						modalType={'confirmModal'}
						loading={updateLoading}
						zIndex={999}
					/>
				</>
			)}
			{modalOpen.commentModal && (
				<>
					<FlexModal
						setShow={handleModal}
						updateLoading={reqLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						btn1='Add'
						callback={() => {
							post({
								...newCommentData,
								teamUuid: teamState.currentTeam.teamUuid,
								orderUuid: order.orderUuid,
								userUuid: splitUserId(userState.currUser.sub),
								name: userState.currUser.name,
							});
						}}
						modalName={'commentModal'}
						zIndex={99}
						isForm
						closeBtn={true}
						body={
							<OrderCommentForm
								fillAndOpenRightSidebar={fillAndOpenRightSidebar}
								handleData={handleNewCommentData}
								formData={newCommentData}
								handleModal={handleModal}
								customErrors={customErrors}
							/>
						}
					/>
				</>
			)}
			{modalOpen.editModal?.open && (
				<>
					<OrderEditModal
						setShow={handleModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						handleData={handleNewOrderData}
						formData={newOrderData}
						handleSelectData={handleFormSelectData}
						type={modalOpen['editModal']?.type}
						setNewOrderData={setNewOrderData}
						patchOrder={patchOrder}
						orderData={order}
					/>
					<Overlay loadOverlay={showOverlay} showOverlay={showOverlay} />
				</>
			)}
			{modalOpen.addToPackModal?.open && (
				<>
					<FlexModal
						setShow={handleModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						callback={addToPack}
						modalName={'addToPackModal'}
						closeBtn={true}
						zIndex={99}
						isForm
						body={
							<AddToPackForm
								handleSelectData={handleChosenPackSelectData}
								chosenPack={chosenPackage}
								packages={packages}
							/>
						}
					/>
				</>
			)}
			{modalOpen.imgModal?.open === true && (
				<>
					<ImgModal
						setShow={handleModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						src={modalOpen['imgModal']?.file?.url}
						downloadImg={downloadFile}
						file={modalOpen['imgModal']?.file}
					/>
					<Overlay loadOverlay={showOverlay} showOverlay={showOverlay} />
				</>
			)}
			{modalOpen.orderItemNewModal && (
				<>
					<OrderItemNewModal
						handleModal={handleModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						packages={packages}
						handleData={handleItemNewData}
						formData={itemNewData}
						postItem={postItemNew}
						missingOptions={{
							message: 'Add a package',
							callback: () => {
								handleModal('packageNewModal', true);
							},
						}}
						handleFormSelectData={handleItemNewSelectData}
						zIndex={97}
					/>
				</>
			)}
			{modalOpen.packageNewModal && (
				<>
					<PackageNewModal
						setShow={handleModal}
						formData={packageNewData}
						setFormData={setPackageNewData}
						setPackages={handlePackages}
						missingOptions={{
							message: 'Add a courier',
							callback: () => {
								handleModal('courierNewModal', true);
							},
						}}
						couriers={couriers}
						setCouriers={setCouriers}
						packages={packages}
						customErrors={customErrors}
						setCustomErrors={setCustomErrors}
						address={packageNewData.address}
						addresses={addresses}
						handleFadeOutModal={handleFadeOutModal}
						handleModal={handleModal}
						handleFocused={handleFocused}
						focused={focused}
						addressForm={false}
						isSeller={isSeller}
						zIndex={98}
					/>
				</>
			)}
			{modalOpen.courierNewModal && (
				<>
					<FlexModal
						setShow={handleModal}
						updateLoading={updateLoading}
						callback={postCourierNew}
						modalName={'courierNewModal'}
						closeBtn={true}
						zIndex={99}
						btn2={'Cancel'}
						isForm
						body={
							<CourierNewForm
								handleFormInput={handleCourierNewData}
								handleFormSelectData={handleCourierSelectData}
								formData={courierNewData}
								chosenAddress={courierNewData.address}
								handleAddressInput={handleCourierAddressInput}
								customErrors={customErrors}
							/>
						}
					/>
				</>
			)}
			{modalOpen.externalAttachmentModal && (
				<>
					<FlexModal
						setShow={handleModal}
						updateLoading={updateLoading}
						callback={postExternalAttachments}
						modalName={'externalAttachmentModal'}
						closeBtn={true}
						zIndex={99}
						btn1={'Submit'}
						btn2={'Cancel'}
						isForm
						body={
							<ExternalAttachmentForm
								formData={newExternalAttachment}
								orderExternalAttachments={orderExternalAttachments}
								handleFormData={handleExternalAttachmentData}
								addExternalAttachment={addExternalAttachment}
								deleteExternalAttachment={deleteExternalAttachment}
								customErrors={customErrors}
							/>
						}
					/>
				</>
			)}
			{modalOpen.holdModal?.open && (
				<>
					<FlexModal
						setShow={handleModal}
						updateLoading={updateLoading}
						setUpdateLoading={setUpdateLoading}
						setShowOverlay={setShowOverlay}
						callback={updateOrderHold}
						modalName={'holdModal'}
						closeBtn={true}
						btn1='Submit'
						zIndex={99}
						isForm
						body={
							<HoldOrderForm
								handleData={handleHoldData}
								handleFormSelectData={handleHoldSelectData}
								formData={holdData}
							/>
						}
					/>
				</>
			)}
			{modalOpen.historyLogModal?.open && (
				<>
					<FlexModal
						setShow={handleModal}
						setShowOverlay={setShowOverlay}
						modalName={'historyLogModal'}
						closeBtn={true}
						zIndex={99}
						body={<History orderUuid={order?.orderUuid} teamUuid={teamState?.currentTeam?.teamUuid} />}
					/>
				</>
			)}
			{loaded ? (
				<div className={`orderDetailsCont ${isSeller ? 'sale' : 'purchase'}`} ref={pageTopRef}>
					<Helmet>
						<title>{metaFieldsByPage?.order?.title.replace('{{ORDER_REF}}', order.orderRef)}</title>
						<meta name='description' content={metaFieldsByPage?.order?.description} />
					</Helmet>
					{suggestedPriceStatus !== null && suggestedPriceStatus === 'declined' && isSeller && (
						<div className='orderNotificationBar'>
							<h4>Last price suggestion was declined by buyer</h4>
						</div>
					)}
					{suggestedPrice &&
						!isSeller &&
						suggestedPriceStatus !== 'accepted' &&
						suggestedPriceStatus !== 'declined' && (
							<div className='orderNotificationBar'>
								<p>
									Accept proposed price of{' '}
									{Number(suggestedPrice?.notificationData.price)?.toFixed(2)}£?
								</p>
								<button
									className='functionalButtonSecondary'
									onClick={() => {
										patchOrder({
											totalPrice: suggestedPrice?.notificationData.price,
										});
										updateNotificationStatus('accepted');
										handleFadeOutModal({
											show: true,
											isFading: false,
											message: 'New price was set',
										});
									}}>
									Yes
								</button>
								<button
									className='functionalButtonSecondary'
									onClick={() => {
										updateNotificationStatus('declined');
									}}>
									No
								</button>
							</div>
						)}
					{order?.hold?.isHold && order?.orderProcessingStatus < 8 && (
						<>
							{order?.hold?.private ? (
								<>
									{order?.hold?.teamUuid === teamState?.currentTeam?.teamUuid ? (
										<Badge
											theme='medium'
											title={'This order is on hold'}
											type='warning'
											badgeText='Hold'
											tag={'This hold is private and can only be seen by your team'}
											message={`${order?.hold?.holdDescription}`}
											reason={`Reason: ${makeFirstCharUpper(
												order?.hold?.reason?.replaceAll('_', ' ')
											)}`}
											badgeIcon={<ProgressHoldIcon />}
											fill
										/>
									) : (
										<></>
									)}
								</>
							) : (
								<Badge
									theme='medium'
									title={'This order is on hold'}
									type='warning'
									badgeText='Hold'
									message={`${order?.hold?.holdDescription}`}
									reason={`Reason: ${makeFirstCharUpper(order?.hold?.reason?.replaceAll('_', ' '))}`}
									badgeIcon={<ProgressHoldIcon />}
									button1={
										order?.hold?.confirmationRequired &&
										order?.hold?.teamUuid !== teamState?.currentTeam?.teamUuid &&
										order?.hold?.confirmationRequired?.isDone !== true
											? {
													text: 'Mark as done',
													callback: markHoldAsDone,
													icon: <CheckIcon />,
											  }
											: null
									}
									mark={
										order?.hold?.confirmationRequired?.isDone === true &&
										order?.hold?.teamUuid === teamState?.currentTeam?.teamUuid
											? 'The other party has marked this hold as done'
											: ''
									}
									fill
								/>
							)}
						</>
					)}
					<div className='floatingHeader'>
						{!isSeller ? (
							<h2 className='marginLeft-20'>
								{order?.sellerTeamName ? order?.sellerTeamName : order?.sellerUserName}
							</h2>
						) : (
							<h2 className='marginLeft-20'>
								{order?.buyerTeamName ? order?.buyerTeamName : order?.buyerUserName}
							</h2>
						)}
						<div className='btnCont'>
							<Button
								text='History log'
								style='secondary'
								onClick={() =>
									navigate(
										`/history?orderRef=${order?.orderRef}&returnUri=/orders/${order?.orderUuid}?orderRef=${order?.orderRef}`
									)
								}
							/>
							{isSeller && order?.orderProcessingStatus < 8 && (
								<>
									{!order?.hold?.isHold ? (
										<Button
											text='Put order on hold'
											style='secondary'
											onClick={() =>
												handleModal('holdModal', {
													open: true,
												})
											}
										/>
									) : (
										<Button
											text='Remove hold'
											style='secondary'
											onClick={() => updateOrderHold(true)}
										/>
									)}
								</>
							)}
							{/* <div className='btn marginRight-20'>
								<TrustedAddBtn
									supplierUuid={isSeller ? order?.buyerTeamUuid : order?.sellerTeamUuid}
									supplierName={isSeller ? order?.buyerTeamName : order?.sellerTeamName}
									iconClass='orderPageIcon'
								/>
							</div> */}
							{!checkStatusIfDisable(order.orderProcessingStatus, 'chat') ? (
								<div
									className='orderPageDiscIcon discIcon'
									onClick={() => fillAndOpenRightSidebar('chat')}>
									<ChatIcon />
									{checkUnreadMessages(
										notificationsState.notifications,
										'message.created',
										order?.orderUuid
									) ? (
										<CircleSolidIcon iconClass='unreadCircleIconRed' />
									) : (
										<></>
									)}
								</div>
							) : (
								<div className='orderPageDiscIcon discIcon disabledDiv'>
									<ChatIcon />
									{checkUnreadMessages(
										notificationsState.notifications,
										'message.created',
										order?.orderUuid
									) ? (
										<CircleSolidIcon iconClass='unreadCircleIconRed' />
									) : (
										<></>
									)}
								</div>
							)}
						</div>
						{order.orderProcessingStatus === 0 && (
							<div className='btnCont'>
								<button
									className={`btn3-primary marginLeft-20`}
									onClick={() => {
										patchOrder({ orderProcessingStatus: 1 });
									}}>
									Send order to seller
								</button>
							</div>
						)}
					</div>
					<OrderGrid
						isLMMember={teamState.currentTeam?.integrations?.labmanager}
						fillAndOpenRightSidebar={fillAndOpenRightSidebar}
						suggestedPriceStatus={suggestedPriceStatus}
						externalAttachments={externalAttachments}
						handleFadeOutModal={handleFadeOutModal}
						handleNewOrderData={handleNewOrderData}
						setShowPriceInput={setShowPriceInput}
						changeReqFields={changeReqFields}
						showPriceInput={showPriceInput}
						setShowOverlay={setShowOverlay}
						handleDropdown={handleDropdown}
						updatedFields={updatedFields}
						expandItem={setExpandedItems}
						expandedItems={expandedItems}
						changeReqUuid={changeReqUuid}
						newOrderData={newOrderData}
						generalFiles={generalFiles}
						downloadFile={downloadFile}
						toggleHidden={toggleHidden}
						dropdownOpen={dropdownOpen}
						suggestPrice={suggestPrice}
						docketFields={docketFields}
						handleModal={handleModal}
						viewerOpen={viewerOpen}
						handleFiles={handleFiles}
						parentOrder={parentOrder}
						changeMade={changeMade}
						patchOrder={patchOrder}
						removeFile={removeFile}
						removeItem={removeItem}
						userState={userState}
						teamState={teamState}
						isSeller={isSeller}
						newFiles={newFiles}
						packages={packages}
						seller={seller} // Connected to Invoicing, fetch from other place later
						order={order}
						imgs={imgs}
					/>
				</div>
			) : (
				<Loading type='circle' />
			)}
		</>
	);
}
