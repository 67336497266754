import React from 'react';
import { Tooltip } from 'react-tooltip';

export default function TeamIcon({ team, tooltip, imgStyle, imgClass, style }) {
	return (
		<>
			{team ? (
				<>
					{team?.teamIcon ? (
						<img
							className={imgClass ? imgClass : ''}
							style={imgStyle ? imgStyle : {}}
							src={team.teamIcon}
							alt='teamIcon'
							data-tooltip-id={tooltip}
							data-tooltip-content={tooltip}
							data-tooltip-delay-show={500}
						/>
					) : (
						<div
							className={`teamChoicesIcon${team?.avatarColor ? ` ${team.avatarColor}` : ''}`}
							data-tooltip-id={tooltip}
							data-tooltip-content={tooltip}
							data-tooltip-delay-show={500}
							style={style}>
							{team.teamInitials?.slice(0, 2)?.toUpperCase()}
						</div>
					)}
				</>
			) : (
				<div className={`teamChoicesIcon`} style={style}></div>
			)}
			{tooltip && <Tooltip id={tooltip} style={{ maxWidth: '300px', textAlign: 'left' }} />}
		</>
	);
}
